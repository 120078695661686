import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/themify-icons.css';
import './App.scss';
import Header from './components/Header';
import IntroSection from './components/IntroSection';
import StorySection from './components/StorySection';
import BoxSection from './components/BoxSection';
import CountdownSection from './components/CountdownSection';
import GettingMarriedSection from './components/GettingMarriedSection';
import SeeYouSection from './components/SeeYouSection';
import GallerySection from './components/GallerySection';
import GettingThereSection from './components/GettingThereSection';
import RspvSection from './components/RspvSection';
import AnnounceSection from './components/AnnounceSection';
import FaqSection from './components/FaqSection';


function App() {
  return (
    <div className="App">
      <Header />
      <IntroSection />
      <GettingMarriedSection id="getting-married" />
      <CountdownSection id="countdown" />
      <StorySection id="our-story" />
      <SeeYouSection id="seeyou" />
      <BoxSection id="when-where" />
      <GallerySection id="gallery" />
      <GettingThereSection id="gettingthere"
        title='Getting there'
        subtitle=''
        cards={[
          {
            title: 'Airport',
            image: 'images/avion.webp',
            body: <>
              <p>Guadalajara International Airport</p>
              <p>The Guadalajara Airport is located 40 kilometers (40 minutes) from the venue where we will be celebrating the wedding. We recommend that all our guests flying to Mexico arrive at this airport.</p>
              <p><i className='ti-location-pin'></i> Carr. Guadalajara - Chapala Km 17.5, 45659 Jal., México</p>
            </>
          },
          {
            title: 'Transportation',
            image: 'images/transporte.webp',
            body: <>
              <p>Beboy transportes</p>
              <p>Magaly Ruiz - +52 1 33 3105 1698 (whatsApp)</p>
              <p>Private transportation. Please contact them via WhatsApp, providing your arrival time and pick-up location.</p>
            </>
          },
          {
            title: 'Taxi',
            image: 'images/taxi.webp',
            body: <>
              <p>Taxi or Private Transportation Service</p>
              <p>Uber cannot operate at the airport, so this option is not recommended. You can use the airport's private taxi service, which is safe and reliable.</p>
            </>
          },
          {
            title: 'Wedding Venue',
            image: 'images/ajijic.webp',
            body: <>
              <p>Nimue Marina Residence & Hotel Boutique</p>
              <p><i className='ti-location-pin'></i> P.º de La Huerta No 57, Col. La Floresta, 45920 Ajijic, Jal., México</p>
            </>
          },
          {
            title: 'Ceremony',
            image: 'images/templo.webp',
            body: <>
              <p>Templo San Jose de la floresta</p>
              <p><i className='ti-location-pin'></i> P.º del Mirador 36, Fracc. La Floresta, 45920 Ajijic, Jal., México</p>
            </>
          },
          {
            title: 'Welcome party',
            image: 'images/hotel-real-chapala.webp',
            body: <>
              <p>Hotel real de chapala </p>
              <p><i className='ti-location-pin'></i> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
              </p>
            </>
          }
        ]} />
      <GettingThereSection id="hotels"
        title={null}
        subtitle='Hotels'
        cards={[
          {
            title: 'Hotel Real de Chapala',
            image: 'images/hotel-real-chapala.webp',
            body: <>
              <p>Hotel real de chapala </p>
              <p>
                This hotel is a 2-minute walk from Nimue, where the reception will be held. It is our top recommendation to stay close and enjoy to the fullest. We are sharing <a href='/hotel-real-chapala.pdf' target='_blank'>this PDF</a> so you can use the reservation code for our wedding. Additionally, we will be hosting the welcome party and the post-wedding brunch at this hotel.
              </p>
              <p><a href='https://realdechapala.com/'>https://realdechapala.com</a></p>
              <p><i className='ti-location-pin'></i> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
              </p>
            </>
          },
          {
            title: 'Monte coaxala',
            image: 'images/montecoaxala.webp',
            body: <>
              <p><a href='https://www.montecoxala.com/'>https://www.montecoxala.com</a></p>
            </>
          },
          {
            title: 'Hotel danza del sol',
            image: 'images/danzadelsol.webp',
            body: <>
              <p><a href='http://hoteldanzadelsolcontacto.mx/'>http://hoteldanzadelsolcontacto.mx</a></p>
            </>
          }
        ]} />
      <AnnounceSection id="present"
        title={<span><i className='ti-gift' /> Honeymoon funds</span>}
        body={<>
          <p>
            We are so happy you can join us on our special day! We will enjoy your gift during our honeymoon. We’d love for you to leave a note suggesting a fun activity or adventure for us to try. We promise to send back memories and photos!
          </p><p>
            Thank you for being part of our lives and this incredible adventure!
          </p>
        </>} />
      <RspvSection id="rspv" />
      <FaqSection id="faq"
        logo={"/icons/logo.png"}
        title={"Questions"}
        subtitle={""}
        faqs={[{
          title: 'What to do in Ajijic, Jalisco?',
          response: <p>We would love for you to join us for our wedding in Ajijic,
            a charming village on the shores of Lake Chapala, known for its traditional charm and vibrant artistic community.
            Located just 40 minutes (40 km) from Guadalajara International Airport.
            Enjoy its colorful streets, delicious cuisine, and warm hospitality.
            To get there, you can fly into Guadalajara and then take a taxi or private transportation to Ajijic.
            Don't forget to try the local specialties and explore the lakeside boardwalk.
            We're sure you'll fall in love with this place as much as we have!<br />
            Here are some ideas:
            <ul>
              <li><a href='https://vm.tiktok.com/ZMrm1m11P/'>https://vm.tiktok.com/ZMrm1m11P/</a></li>
              <li><a href='https://vm.tiktok.com/ZMraoqDec/'>https://vm.tiktok.com/ZMraoqDec/</a></li>
            </ul>
          </p>
        }, {
          title: 'What weather should we expect?',
          response: <p>
            In Ajijic, Jalisco, the weather in March is quite pleasant.
            During this month, the average high temperatures are around 29°C (84°F),
            while the average low temperatures are about 10°C (50°F).
            The weather is generally dry, with an average precipitation of only 5 mm (0.20 inches),
            making the air less humid compared to other months of the year (Time and Date) (Weather Underground).
          </p>
        }, {
          title: 'What Should I Wear? Dress Code.',
          response: <div>
            <p><i className='ti-music-alt' /> Welcome party - Casual, cocktail.</p>
            <p><i className='ti-instagram' /> Ceremonia - Formal. Here are some dress code ideas <a href="https://vm.tiktok.com/ZMraVs2Jy/">https://vm.tiktok.com/ZMraVs2Jy</a> and <a href="https://vm.tiktok.com/ZMk5x2383/">https://vm.tiktok.com/ZMk5x2383</a></p>
          </div>
        }, {
          title: 'Our favorite local restaurants, a bit of gastronomy.',
          response: <div>
            <h4>Restaurant "oia cocina de mar"</h4>
            <p><i className='ti-location-pin' />P.º Ramón Corona 32, Chapala Centro, 45900 Chapala, Jal., México</p>
            <h4>La VitaBella Soul Spa Hotel & Restaurant</h4>
            <p>
              <a href="http://www.lavitabellasoulspa.com/"></a>
            </p>
            <p>
              <i className='ti-location-pin' /> Rafael Osuna 250, Raquet Club, 45820 San Juan Cosalá, Jal., México
            </p>
          </div>
        }, {
          title: 'How to Get from the Ceremony to the Venue?',
          response: <p>
            The distance between the church and the venue is 1.3 km.
            You can use the private transportation available in the area or even walk.
            Valet parking will be available at the venue for those who drive their own car.
          </p>
        }]} />
      < AnnounceSection id="footer"
        logo={"/icons/logo.png"}
        title={" "}
        className={'bg-pink'}
        text={"22 de Marzo de 2025 — Ajijic, Jalisco, México"} />

    </div >
  );
}

export default App;
